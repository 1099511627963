import { Switch, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { FC } from 'react';
import { ChangeDataSchema } from './SettingsCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { cssVar } from 'utils/css';

const PREFIX = 'ToggleSettings';

const classes = {
  iconButton: `${PREFIX}IconButton`,
  iconButtonChecked: `${PREFIX}IconButtonChecked`,
  iconButtonUnChecked: `${PREFIX}IconButtonUnChecked`,
};

const Root = styled('div')({
  [`& .${classes.iconButton}`]: {
    borderRadius: '1.625rem',
    height: '1.625rem',
    pointerEvents: 'none',
    width: '3.375rem',
    '& svg': {
      color: cssVar('--color-white'),
    },
  },
  [`& .${classes.iconButtonChecked}`]: {
    backgroundColor: cssVar('--color-pistachio'),
  },
  [`& .${classes.iconButtonUnChecked}`]: {
    backgroundColor: cssVar('--color-wafer'),
  },
});

export interface ToggleSettingsProps {
  readonly name: string;
  readonly value: boolean;
  readonly disabled?: boolean;
  readonly onChange: (changedValue: ChangeDataSchema) => Promise<unknown>;
}

const ToggleSettings: FC<ToggleSettingsProps> = ({ name, value, disabled, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    setIsChecked(value);
  }, []);

  const handleChange = async (checked: boolean) => {
    setLoading(true);
    try {
      await onChange({
        key: name,
        value: checked ? 1 : 0,
      });
      setIsChecked(!isChecked);
      setStatus('success');
      setTimeout(() => {
        setStatus(null);
      }, 2000);
    } catch (e) {
      setStatus('error');
      setTimeout(() => {
        setStatus(null);
      }, 2000);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const renderStatus = () => {
    if (status === 'success') {
      return <FontAwesomeIcon icon={faCheck} />;
    } else if (status === 'error') {
      return <FontAwesomeIcon icon={faTimes} />;
    }
    if (loading) {
      return <CircularProgress size={18} />;
    }
  };

  return (
    <Root>
      {loading || status ? (
        <IconButton
          className={`${classes.iconButton} ${
            isChecked ? classes.iconButtonChecked : classes.iconButtonUnChecked
          }`}
          size="large"
        >
          {renderStatus()}
        </IconButton>
      ) : null}
      {!loading && !status && (
        <Switch
          color="primary"
          name={name}
          onChange={(_event: any, checked: boolean) => handleChange(checked)}
          checked={isChecked}
          {...(disabled && { disabled: true })}
        />
      )}
    </Root>
  );
};

export default ToggleSettings;
